import React, { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [profilePicture, setProfilePicture] = useState("");
  const [bannerPicture, setBannerPicture] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode

  useEffect(() => {
    const loggedInEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (!loggedInEmail || !token) {
      toast.error("No logged in user found.");
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        if (result.status === 200 && result.data) {
          const user = result.data;

          setUsername(user.Username);
          setEmail(user.Email);
          setPaymentStatus(user.PaymentStatus);
          setCreatedAt(new Date(user.CreatedAt).getFullYear());

          setProfilePicture(
            user.ProfileImage
              ? `data:image/jpeg;base64,${user.ProfileImage}`
              : "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/Group%2035(1).png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9Hcm91cCAzNSgxKS5wbmciLCJpYXQiOjE3MzY5NTE0NTgsImV4cCI6MTc2ODQ4NzQ1OH0.ZmYxNXZmZVwf24ONCbIwlbrosQNQaOC-2PYdyyt-7DI&t=2025-01-15T14%3A30%3A58.892Z"
          );
          setBannerPicture(
            user.ProfileBanner
              ? `data:image/jpeg;base64,${user.ProfileBanner}`
              : "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/banner_image/cartoon-ai-robot-scene.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9iYW5uZXJfaW1hZ2UvY2FydG9vbi1haS1yb2JvdC1zY2VuZS5qcGciLCJpYXQiOjE3MzY5NTE4NzAsImV4cCI6MTc2ODQ4Nzg3MH0.9CkH3rUDK8eK8Qo8qaHtjnjnqkTsqeJyV3ODgB6J-SM&t=2025-01-15T14%3A37%3A50.938Z"
          );
        } else {
          toast.error("User data not found.");
        }
      } catch (error) {
        toast.error("Error fetching user data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true); // Set editing mode to true
  };

  const handleCloseEdit = () => {
    setIsEditing(false); // Close the edit profile modal
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div className="w-full max-w-3xl">
      <div className="relative">
        <img
          src={bannerPicture || "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/banner_image/cartoon-ai-robot-scene.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9iYW5uZXJfaW1hZ2UvY2FydG9vbi1haS1yb2JvdC1zY2VuZS5qcGciLCJpYXQiOjE3MzY5NTE4NzAsImV4cCI6MTc2ODQ4Nzg3MH0.9CkH3rUDK8eK8Qo8qaHtjnjnqkTsqeJyV3ODgB6J-SM&t=2025-01-15T14%3A37%3A50.938Z"}
          alt="Banner"
          className="w-full h-52 object-cover rounded-lg mb-6"
        />
        <div className="absolute bottom-0 left-0 ml-6 mb-4 transform translate-y-1/2">
          <img
            src={profilePicture || "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/Group%2035(1).png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9Hcm91cCAzNSgxKS5wbmciLCJpYXQiOjE3MzY5NTE0NTgsImV4cCI6MTc2ODQ4NzQ1OH0.ZmYxNXZmZVwf24ONCbIwlbrosQNQaOC-2PYdyyt-7DI&t=2025-01-15T14%3A30%3A58.892Z"}
            alt="User Profile"
            className="w-32 h-32 rounded-full border-4 border-gray-200"
          />
        </div>
        <div className="absolute top-4 right-4">
          <a href="/profile-edit">
            <button className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-800 focus:bg-blue-800 focus:outline-none transition-colors">
              Edit Profile
            </button>
          </a>
        </div>
      </div>
      <div className="border border-gray-800 p-6 rounded-lg shadow-xl">
        <h2 className="text-3xl text-gray-800 font-semibold mb-6 text-center">
          Account Information
        </h2>
        <div className="text-gray-800 mb-4 grid grid-cols-2 gap-4">
          <div className="mb-2">
            <span className="font-semibold">Username: </span>
            {username}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Email: </span>
            {email}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Payment Status: </span>
            {paymentStatus === "paid" ? (
              <FaCheck className="text-green-500" />
            ) : (
              <FaTimes className="text-red-500" />
            )}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Created At: </span>
            This account has been around since {createdAt}
          </div>
        </div>
      </div>
    </div>
  );
};

// Your SkeletonLoader component remains the same
const SkeletonLoader = () => {
  return (
    <div className="w-full max-w-3xl">
      <div className="relative">
        <div className="w-full h-52 bg-gray-300 rounded-lg mb-6 animate-pulse"></div>
        <div className="absolute bottom-0 left-0 ml-6 mb-4 transform translate-y-1/2">
          <div className="w-32 h-32 bg-gray-300 rounded-full border-4 border-gray-200 animate-pulse"></div>
        </div>
        <div className="absolute top-4 right-4">
          <div className="w-24 h-10 bg-blue-300 rounded-full animate-pulse"></div>
        </div>
      </div>
      <div className="bg-gray-200 p-6 rounded-lg shadow-md">
        <h2 className="w-48 h-8 bg-gray-300 rounded-md mb-6 mx-auto animate-pulse"></h2>
        <div className="text-white mb-4 grid grid-cols-2 gap-4">
          <div className="mb-2 w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
          <div className="mb-2 w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
          <div className="mb-2 w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
          <div className="mb-2 w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
