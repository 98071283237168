import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  EnvelopeIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [phone_numberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value && !value.includes("@")) {
      setEmailError("Emails must use '@'.");
    } else {
      setEmailError("");
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (!value) {
      setUsernameError("Username is required.");
    } else {
      setUsernameError("");
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (!value) {
      setPhoneNumberError("Phone number is required.");
    } else {
      setPhoneNumberError("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (value && !regex.test(value)) {
      setPasswordError("The password must contain letters and numbers.");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (password && value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      if (!email) {
        toast.error("Please enter your email.");
        return;
      }
      if (emailError) {
        toast.error("Please correct the email error.");
        return;
      }
    }
    if (currentStep === 2) {
      if (!phone_number) {
        toast.error("Please enter your phone number.");
        return;
      }
      if (usernameError) {
        toast.error("Please correct the username error.");
        return;
      }
    }
    if (currentStep === 3 && (passwordError || confirmPasswordError)) {
      toast.error("Please correct the password errors.");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      emailError ||
      passwordError ||
      confirmPasswordError ||
      usernameError ||
      phone_numberError
    ) {
      toast.error("Please correct any errors in the form.");
      return;
    }

    setLoading(true);

    const requestData = {
      email,
      phone_number,
      username,
      password,
    };

    fetch(
      "https://convogenius-backend-production.up.railway.app/api/v1/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(
              data.message ||
                "Registration failed. Try using another email and username."
            );
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setRegistrationSuccess(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(
          error.message || "Registration failed. Your email is already in use."
        );
      });
  };

  const handleSuccessModalClose = () => {
    setRegistrationSuccess(false);
    window.location.href = "/login";
  };

  const handleKeyDown = (e, step) => {
    if (e.key === "Enter") {
      if (step < 3) {
        handleNextStep();
      } else if (step === 3) {
        handleSubmit(e);
      }
    }
  };
  return (
    <div className="min-h-screen flex justify-center items-center bg-gradient-to-br from-blue-500 via-white to-blue-300  p-4">
      <Helmet>
        <title>Sign Up | Convogenius</title>
        <meta
          name="description"
          content="Sign Up for Convogenius to create an account and access our branding and sales applications powered by AI. Easy and secure registration process."
        />
        <meta
          name="keywords"
          content="register, Convogenius, account creation, branding, sales applications, AI"
        />
        <meta name="author" content="Convogenius Team" />
        <meta property="og:title" content="Sign Up | Convogenius" />
        <meta
          property="og:description"
          content="Sign Up for Convogenius to create an account and access our branding and sales applications powered by AI. Easy and secure registration process."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta
          property="og:url"
          content="https://www.convogenius.my.id/register"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Convogenius" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sign Up | Convogenius" />
        <meta
          name="twitter:description"
          content="Sign Up for Convogenius to create an account and access our branding and sales applications powered by AI. Easy and secure registration process."
        />
        <meta
          name="twitter:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <link rel="canonical" href="https://www.convogenius.my.id/register" />
        {/* Google Tag Manager */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LR5JNC7FGT"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LR5JNC7FGT');
        `}
        </script>
      </Helmet>
      <div className="bg-white border border-gray-200 max-w-md w-full lg:w-2/3 xl:w-1/2 p-8 lg:p-12 rounded-2xl shadow-md flex flex-col justify-center items-center lg:items-start">
        <h2 className="text-3xl font-semibold text-center mb-4 lg:text-left text-gray-800">
          Sign Up At ConvoGenius
        </h2>
        <form onSubmit={handleSubmit} className="w-full">
          {currentStep === 1 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-800 mb-1"
              >
                Email
              </label>
              <div className="relative flex items-center">
                <EnvelopeIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                  className={`pl-10 p-3 w-full bg-white border ${
                    emailError ? "border-blue-500" : "border-gray-500"
                  } rounded-md text-gray-800 focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Your email"
                  required
                />
              </div>
              {emailError && (
                <p className="text-sm text-blue-500 mt-1">{emailError}</p>
              )}
              <button
                type="button"
                onClick={handleNextStep}
                className={`mt-4 py-2 px-4 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 w-full ${
                  !email ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!email}
              >
                Next
              </button>
            </div>
          )}

          {currentStep === 2 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium text-gray-800 mb-1"
              >
                Phone Number
              </label>
              <div className="relative flex items-center">
                <PhoneIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type="tel" // Change type to "tel" for numeric keyboard on mobile
                  id="phone_number"
                  name="phone_number"
                  value={phone_number}
                  onChange={handlePhoneNumberChange}
                  onInput={(e) => {
                    // Allow only digits and limit to 13 characters
                    const newValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                    if (newValue.length <= 13) {
                      e.target.value = newValue; // Set the new value if it's within limit
                    } else {
                      e.target.value = newValue.slice(0, 13); // Trim to max 13 digits
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                  className={`pl-10 p-3 w-full bg-white border ${
                    phone_numberError ? "border-blue-500" : "border-gray-500"
                  } rounded-md text-gray-800 focus:ring-gray-500 focus:ring-1`}
                  placeholder="Your phone number"
                  required
                />
              </div>
              {usernameError && (
                <p className="text-xs text-blue-500 mt-1">{usernameError}</p>
              )}
              <div className="flex flex-col mt-4">
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`py-2 px-4 mb-2 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 ${
                    !phone_number ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!phone_number}
                >
                  Next
                </button>
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="py-2 px-4 bg-white border border-gray-800 text-gray-800 rounded-2xl hover:text-gray-900 hover:border-gray-900 focus:outline-none focus:ring-2"
                >
                  Previous
                </button>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-800 mb-1"
              >
                Username
              </label>
              <div className="relative flex items-center">
                <UserIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                  className={`pl-10 p-3 w-full text-gray-800 bg-white border ${
                    usernameError ? "border-blue-500" : "border-gray-500"
                  } rounded-md focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Your username"
                  required
                />
              </div>
              {usernameError && (
                <p className="text-sm text-blue-500 mt-1">{usernameError}</p>
              )}
              <div className="flex flex-col mt-4">
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`py-2 px-4 mb-2 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 ${
                    !username ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!username}
                >
                  Next
                </button>
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="py-2 px-4 bg-white border border-gray-800 text-gray-800 rounded-2xl hover:text-gray-900 hover:border-gray-900 focus:outline-none focus:ring-2"
                >
                  Previous
                </button>
              </div>
            </div>
          )}

          {currentStep === 4 && (
            <div className="mb-4 w-full relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-800 mb-1"
              >
                Password
              </label>
              <div className="relative flex items-center">
                <LockClosedIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => handleKeyDown(e, 3)}
                  className={`pl-10 p-3 w-full text-gray-800 bg-white border ${
                    passwordError ? "border-blue-500" : "border-gray-500"
                  } rounded-md focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Your password"
                  required
                />
                <div
                  className="absolute right-3 h-6 w-6 text-gray-400 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {passwordError && (
                <p className="text-sm text-blue-500 mt-1">{passwordError}</p>
              )}

              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-800 mb-1 mt-4"
              >
                Confirm Password
              </label>
              <div className="relative flex items-center">
                <LockClosedIcon className="absolute left-3 h-6 w-6 text-gray-400" />
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onKeyDown={(e) => handleKeyDown(e, 3)}
                  className={`pl-10 p-3 w-full text-gray-800 bg-white border ${
                    confirmPasswordError
                      ? "border-blue-500"
                      : confirmPassword
                      ? confirmPassword === password
                        ? "border-green-500"
                        : "border-blue-500"
                      : "border-gray-500"
                  } rounded-md focus:ring-gray-500 focus:border-gray-500 shadow-sm placeholder-gray-400 outline-none`}
                  placeholder="Confirm your password"
                  required
                />
                <div
                  className="absolute right-3 h-6 w-6 text-gray-400 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {confirmPasswordError && (
                <p className="text-sm text-blue-500 mt-1">
                  {confirmPasswordError}
                </p>
              )}

              <div className="flex flex-col mt-4">
                <button
                  type="submit"
                  className="py-2 px-4 mb-2 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2"
                >
                  Sign Up
                </button>
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="py-2 px-4 bg-white border border-gray-800 text-gray-800 rounded-2xl hover:text-gray-900 hover:border-gray-900 focus:outline-none focus:ring-2"
                >
                  Previous
                </button>
              </div>
            </div>
          )}
        </form>
        <p className="mt-4 text-gray-800 text-center lg:text-left">
          Already Sign Up?{" "}
          <a
            href="/login"
            className="text-gray-800 hover:text-blue-700 font-bold"
          >
            Sign In
          </a>
        </p>
      </div>
      <ToastContainer position="top-right" />
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <ClipLoader color="#2563EB" loading={loading} size={50} />
        </div>
      )}
      {registrationSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-auto">
            <h2 className="text-2xl font-semibold mb-4">
              Registration Successful!
            </h2>
            <p className="mb-4">You have successfully registered.</p>
            <button
              className="py-2 px-4 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2"
              onClick={handleSuccessModalClose}
            >
              Go to Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterPage;
