import React from "react";

const FeatureCards = () => {
  const features = [
    {
      title: "Branding Deskripsi With AI",
      image: "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/chat.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9jaGF0LnBuZyIsImlhdCI6MTczNjI0OTE3NiwiZXhwIjoxNzY3Nzg1MTc2fQ.GaYtdU-hZFGkHkCA3kkNbbac8T_Aww3rDLegpm2Oikc&t=2025-01-07T11%3A26%3A15.217Z", // Replace with actual image path
      link: "/room_cht",
    },
    {
      title: "Branding Image With AI",
      image: "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/draw.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9kcmF3LnBuZyIsImlhdCI6MTczNjI0OTE5OCwiZXhwIjoxNzY3Nzg1MTk4fQ.psEYekFzw6hoPgfH9Vvgj3YzLVLRXlG3MAx6UiKgFSw&t=2025-01-07T11%3A26%3A37.821Z", // Replace with actual image path
      link: "/image-ai",
    },
    {
      title: "Branding Music With AI",
      image: "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/music.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9tdXNpYy5wbmciLCJpYXQiOjE3MzYyNDkyMjAsImV4cCI6MTc2Nzc4NTIyMH0.VZjoUy3pKt41BIlhdYdmEj216uvdp_QSOWIRB47V0cY&t=2025-01-07T11%3A26%3A59.144Z", // Replace with actual image path
      link: "/music-ai",
    },
  ];

  return (
    <div className="mt-2 mb-20 lg:mb-8">
      <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-2 text-center">
        Explore Features
      </h3>
      <p className="text-lg text-gray-600 text-center mb-4 mt-2">
        Let's try the product branding feature using AI
      </p>
      <div className="flex justify-start lg:justify-center overflow-x-auto space-x-4">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white border border-blue-600 p-6 rounded-lg shadow-lg flex flex-col items-center w-64 min-w-max transition duration-300 group hover:bg-blue-800"
          >
            <h4 className="text-gray-800 font-semibold mb-2 text-center transition duration-300 group-hover:text-white">
              {feature.title}
            </h4>
            <img
              src={feature.image}
              alt={feature.title}
              className="w-full h-40 object-cover rounded-lg mb-4 transition duration-300"
            />
            <a href={feature.link}>
              <button className="bg-blue-700 text-white px-4 py-2 rounded-lg font-semibold transition duration-300 group-hover:bg-gray-200 group-hover:text-blue-700">
                Try Now
              </button>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureCards;
