import React from "react";
import { FaTrash } from "react-icons/fa";

const BannerPicture = ({
  bannerPicture,
  setBannerPicture,
  setNewBannerPicture,
}) => {
  const handleBannerPictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBannerPicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveBannerPicture = () => {
    setBannerPicture("https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/banner_image/cartoon-ai-robot-scene.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9iYW5uZXJfaW1hZ2UvY2FydG9vbi1haS1yb2JvdC1zY2VuZS5qcGciLCJpYXQiOjE3MzY5NTE4NzAsImV4cCI6MTc2ODQ4Nzg3MH0.9CkH3rUDK8eK8Qo8qaHtjnjnqkTsqeJyV3ODgB6J-SM&t=2025-01-15T14%3A37%3A50.938Z");
    setNewBannerPicture(null);
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-1">Banner Picture</label>
      <div className="relative mb-2">
        <img
          src={bannerPicture || "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/banner_image/cartoon-ai-robot-scene.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9iYW5uZXJfaW1hZ2UvY2FydG9vbi1haS1yb2JvdC1zY2VuZS5qcGciLCJpYXQiOjE3MzY5NTE4NzAsImV4cCI6MTc2ODQ4Nzg3MH0.9CkH3rUDK8eK8Qo8qaHtjnjnqkTsqeJyV3ODgB6J-SM&t=2025-01-15T14%3A37%3A50.938Z"}
          alt="Banner"
          className="w-full h-40 object-cover rounded-lg"
        />
        <input
          type="file"
          onChange={handleBannerPictureChange}
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
      </div>
      <button onClick={handleRemoveBannerPicture} className="text-red-600 mt-2">
        <FaTrash /> Remove Banner Picture
      </button>
    </div>
  );
};

export default BannerPicture;
