import { motion } from "framer-motion";
import { useState } from "react";
import InfoModal from "./InfoModal";

const MainSection = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openInfoModal = () => setModalOpen(true);
  const closeInfoModal = () => setModalOpen(false);

  return (
    <section
      className="relative min-h-screen w-full flex items-center justify-center animate-gradient rounded-b-2xl"
      style={{ backgroundAttachment: "fixed" }}
      aria-labelledby="convogenius-heading"
    >
      <div className="absolute inset-0 bg-white bg-opacity-30 mix-blend-overlay"></div>
      <div className="relative z-10 text-center p-10">
        <motion.h1
          id="convogenius-heading"
          className="text-4xl lg:text-6xl font-bold mb-4 text-blue-900"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2 }}
        >
          Convogenius AI
        </motion.h1>
        <motion.p
          className="text-lg lg:text-xl mb-8 text-blue-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 2, delay: 0.5 }}
        >
          Empowering your brand with AI-driven content, trends, and business
          optimization.
        </motion.p>
        <div className="flex flex-row justify-center items-center space-x-4">
          <motion.button
            onClick={openInfoModal}
            className="bg-white text-blue-600 border border-blue-600 px-6 py-2 rounded-2xl transition duration-300 hover:bg-blue-700 hover:text-white hover:border-blue-700"
            aria-label="Learn more about the project"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 2, delay: 1 }}
          >
            Learn More
          </motion.button>
        </div>
      </div>

      <InfoModal isOpen={isModalOpen} onClose={closeInfoModal} />

      <style jsx>{`
        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
            background-color: #3b82f6;
          }
          50% {
            background-position: 100% 50%;
            background-color: #bfdbfe;
          }
          100% {
            background-position: 0% 50%;
            background-color: #ffffff;
          }
        }
        .animate-gradient {
          background-image: linear-gradient(270deg, #ffffff, #bfdbfe, #3b82f6);
          animation: gradient-animation 10s ease infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </section>
  );
};

export default MainSection;
